<template>
  <div class="container" style="margin-top: 50px">
    <div class="row">
      <div class="col-lg-1 col-md-1 col-sm-6 col-xs-12">&nbsp;</div>
      <div
        :class="
          'col-lg-' +
          item.lg +
          ' col-md-' +
          item.md +
          ' col-sm-' +
          item.sm +
          ' col-xs-' +
          item.xs
        "
        v-for="item in items"
        :key="item.name"
      >
        <div
          class="card-sl"
          style="cursor: pointer"
          @click.prevent="redirectToForm(item)"
        >
          <div class="card-image">
            <img :src="item.imgsource" />
          </div>
          <a class="card-action" href="javascript:void(0);" v-if="false"
            ><i class="fa fa-heart"></i
          ></a>
          <div class="card-heading" v-if="false">{{ item.name }}</div>
          <a
            href="javascript:void(0);"
            class="card-button"
            @click.prevent="redirectToForm(item)"
            v-html="item.name.replace(' ', '<br />')"
          ></a>
        </div>
      </div>
      <div class="col-lg-1 col-md-1 col-sm-6 col-xs-12">&nbsp;</div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
const usersLanguage = window.navigator.language;
let languageText=Vue.prototype.$en;
if(usersLanguage=='de' || usersLanguage=='de-DE')
{
  languageText=Vue.prototype.$de;
}

export default {
  name: "register-section",
  data() {
    return {
      items: [
        {
          name: languageText.userType.distributor,
          value: this.$func.usertype.distributor,
          imgsource: require("@/assets/img/Truck_Icon.png"),
          lg: 2,
          md: 2,
          sm: 6,
          xs: 12,
          link: "/regentry",
        },
        {
          name: languageText.userType.tradepartner,
          value: this.$func.usertype.tradePartner,
          imgsource: require("@/assets/img/Handshake_Icon.png"),
          lg: 2,
          md: 2,
          sm: 6,
          xs: 12,
          link: "/regentry",
        },
        {
          name: languageText.userType.creativeagency,
          value: this.$func.usertype.default,
          imgsource: require("@/assets/img/Brush_Icon.png"),
          lg: 2,
          md: 2,
          sm: 6,
          xs: 12,
          link: "/regentry",
        },
        {
          name: languageText.userType.promoagency,
          value: this.$func.usertype.promoAgency,
          imgsource: require("@/assets/img/Megaphone_Icon.png"),
          lg: 2,
          md: 2,
          sm: 6,
          xs: 12,
          link: "/regentry",
        },
        {
          name: languageText.userType.beamsuntorycontractor,
          value: this.$func.usertype.beamSuntoryContractor,
          imgsource: require("@/assets/img/ID_Icon.png"),
          lg: 2,
          md: 2,
          sm: 6,
          xs: 12,
          link: "/regentry",
        },
      ],
    };
  },
  methods: {
    redirectToForm: function (itm) {
      const _vm = this;
      _vm.$store.state.selectedUserType = itm.value;
      localStorage.setItem(
        "selectedUserType",
        JSON.stringify(_vm.$store.state.selectedUserType)
      );
      _vm.$router.push({
        path: itm.link,
      });
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
.card-sl {
  /*border-radius: 8px;*/
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-image: linear-gradient(
    to bottom right,
    rgb(255 255 255),
    rgb(255, 255, 255)
  );
}
.card-image img {
  max-height: 100%;
  max-width: 100%;
  border-radius: 8px 8px 0px 0;
}
.card-action {
  position: relative;
  float: right;
  margin-top: -25px;
  margin-right: 20px;
  z-index: 2;
  color: #e26d5c;
  background: #ffffff;
  border-radius: 100%;
  padding: 15px;
  font-size: 15px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.19);
}
.card-action:hover {
  color: #ffffff;
  background: #e26d5c;
  -webkit-animation: pulse 1.5s infinite;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2),
    0 1px 2px 0 rgba(223, 50, 50, 0.19);
}

.card-heading {
  font-size: 18px;
  font-weight: bold;
  background: #fff;
  padding: 10px 15px;
}

.card-text {
  padding: 10px 15px;
  background: #fff;
  font-size: 14px;
  color: #636262;
}
.card-button {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  width: 100%;
  background-color: #2dbcd4;
  background-image: linear-gradient(to bottom right, #135763, #2dbcd4);
  color: #fff;
  font-weight: 400;
  /*border-radius: 0 0 8px 8px;*/
  font-family: Oswald !important;
  font-size: 18px;
  min-height: 68px;
}
.card-button:hover {
  text-decoration: none;
  background-color: #228a9c;
  color: #fff;
}
@-webkit-keyframes pulse {
  0% {
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  70% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba(90, 153, 212, 0);
  }
  100% {
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
  }
}
</style>
