<template>
  <div>
    <div
      id="banner-area"
      style="
        height: 300px !important;
        background: url(img/banner/lifting-spirit.jpg) no-repeat;
      "
    >
      <img src="@/assets/img/banner/lifting-spirit.jpg" v-if="false" alt="" />
      <div class="parallax-overlay"></div>
      <div class="banner-title-content">
        <div class="text-center">
          <h2
            style="text-transform: capitalize; font-family: Oswald !important"
          >
            {{ languageText1.userType.selectusertypetext}}
          </h2>
        </div>
      </div>
    </div>
    <section
      id="main-container"
      style="
        min-height: 65vh;
        height: 65vh;
        background-color: rgba(110, 111, 112, 0.8);
      "
    >
      <register-section></register-section>
    </section>
  </div>
</template>

<script>
import Vue from "vue";
const usersLanguage1 = window.navigator.language;
let languageText1=Vue.prototype.$en;
if(usersLanguage1 =='de' || usersLanguage1 =='de-DE')
{
  languageText1=Vue.prototype.$de;
}
import registerSection from "@/components/register/register-section.vue";
export default {
  components: { registerSection },
  name: "regform-navigator",
  data(){
    return{
      languageText1: languageText1
    }
  },
  mounted() {
    // eslint-disable-next-line no-undef
    particlesJS.load(
      "banner-area",
      process.env.VUE_APP_SERVER_API + "api/ParticleData",
      function () {
        console.log("callback - particles.js config loaded");
      }
    );
  },
};
</script>

<style scoped></style>
