var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container",staticStyle:{"margin-top":"50px"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-1 col-md-1 col-sm-6 col-xs-12"},[_vm._v(" ")]),_vm._l((_vm.items),function(item){return _c('div',{key:item.name,class:'col-lg-' +
        item.lg +
        ' col-md-' +
        item.md +
        ' col-sm-' +
        item.sm +
        ' col-xs-' +
        item.xs},[_c('div',{staticClass:"card-sl",staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.preventDefault();return _vm.redirectToForm(item)}}},[_c('div',{staticClass:"card-image"},[_c('img',{attrs:{"src":item.imgsource}})]),(false)?_c('a',{staticClass:"card-action",attrs:{"href":"javascript:void(0);"}},[_c('i',{staticClass:"fa fa-heart"})]):_vm._e(),(false)?_c('div',{staticClass:"card-heading"},[_vm._v(_vm._s(item.name))]):_vm._e(),_c('a',{staticClass:"card-button",attrs:{"href":"javascript:void(0);"},domProps:{"innerHTML":_vm._s(item.name.replace(' ', '<br />'))},on:{"click":function($event){$event.preventDefault();return _vm.redirectToForm(item)}}})])])}),_c('div',{staticClass:"col-lg-1 col-md-1 col-sm-6 col-xs-12"},[_vm._v(" ")])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }